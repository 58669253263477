<template>
    <div v-if="totalPages > 1" class="pagination-list">
        <!-- <span class="page-record" v-if="rows.meta.from && rows.meta.to">Showing {{rows.meta.from}} to {{rows.meta.to}} of {{rows.meta.total}} results</span> -->
        <ul>
            <!-- Previous links -->
            <template v-if="items.links.prev==null">
                <li class="disabled">
                    <span title="Previous"><i class="fas fa-chevron-left"></i></span>
                </li>
            </template>
            <template v-else>
                <li @click="goToUrl(items.links.prev, $event)">
                    <span><i class="fas fa-chevron-left"></i></span>
                </li>
            </template>
            <!--/ Previous links -->
            <!-- Pagination center -->
            <template v-if="totalPages >= 10">
                <template v-if="totalPages >= (currentPage+5)">
                    <li v-for="number in currentPagesArr" :key="`first-${number}`">
                        <span v-if="items.meta.current_page==number" class="active-pagination">{{ number }}</span>
                        <span :class="items.meta.current_page==number?'active-pagination':''"  v-else  @click="goToUrl(items.meta.path+'?page='+number, $event)">{{ number }}</span>
                    </li>
                </template>
                <template v-else>
                    <li v-for="number in lastItemsForCurrentPagesArr" :key="`first-${number}`">
                        <span v-if="items.meta.current_page==number" class="active-pagination">{{ number }}</span>
                        <span :class="items.meta.current_page==number?'active-pagination':''"  v-else  @click="goToUrl(items.meta.path+'?page='+number, $event)">{{ number }}</span>
                    </li>
                </template>
                <!-- Invisible -->
                <li v-if="totalPages !== currentPage"><span class="v-pagination__more">...</span></li>
                <!-- pagination last -->
                <template v-if="totalPages !== currentPage">
                    <li>
                        <span v-if="items.meta.current_page==lastPage" class="active-pagination">{{ lastPage }}</span>
                        <span :class="items.meta.current_page==lastPage?'active-pagination':''"  v-else  @click="goToUrl(items.meta.path+'?page='+lastPage, $event)">{{ lastPage }}</span>
                    </li>
                </template>
            </template>
            <template v-else>
                <li v-for="(number, index) in totalPages" :key="index" >
                    <span type="button" v-if="items.meta.current_page==number" class="active-pagination">{{ number }}</span>
                    <span type="button" :class="items.meta.current_page==number?'active-pagination':''"  v-else  @click="goToUrl(items.meta.path+'?page='+number, $event)">{{ number }}</span>
                </li>
            </template>
            <!--/ Pagination center -->
            <!--Next page links -->
            <template v-if="totalPages !== currentPage">
                <template v-if="items.links.next!=null">
                    <li @click="goToUrl(items.links.next, $event)">
                        <span><i class="fas fa-chevron-right"></i></span>
                    </li>
                </template>
                <template v-else>
                    <li class="disabled">
                        <span><i class="fas fa-chevron-right"></i></span>
                    </li>
                </template>
            </template>
        </ul>
    </div>
</template>
<script>
import axios from 'axios'
import Helper from "@/services/helper"
export default {
    name:'Pagination',
    props: {
        rows: {
            required: true
        },
        filters: {
            default() {
                return {};
            }
        },
    },
    data() {
        return {
            items: [],
            filterParams: {},
        };
    },
    methods: {
        goToUrl(url, event) {
            event.preventDefault();
            let app = this;
            if (app.filterQuery) {
                url = url + `&${app.filterQuery}`
            }
            if(app.preventDataFetch){
                app.$emit('handlePageChange' , url);
                return;
            }
            if(app.additionalUrlQuery){
                url = url + app.additionalUrlQuery
            }
            this.getData(url);
        },
        getData(url) {
            // if (this.filterQuery) {
            //     url = `${url}&${this.filterQuery}`;
            // }
            axios.get(url).then(response => {
                let app = this;
                if(typeof app.$listeners.setData != "undefined"){
                    app.$emit("setData", response.data);
                } else {
                    let data = response.data.data.data ? response.data.data : response.data;
                    app.$emit("update:rows", data);
                    app.$emit("meta-action", data.meta);
                }
            });
        }
    },
    computed: {
        totalPages() {
            let app = this;
            if (app.items.length === 0) return 1;
            return Math.ceil(app.items.meta.total / app.items.meta.per_page);
        },
        currentPage() {
            let app = this;
            if (app.items.length === 0) return 1;
            return app.items.meta.current_page
        },
        lastPage() {
            let app = this;
            return app.items.meta.last_page
        },
        currentPagesArr() {
            let app = this;
            if (app.items.length === 0) return 1;
            let currentPage = app.items.meta.current_page;
            let lastCurrentPage = currentPage + 4;
            let items = [];
            for (let i = currentPage; i <= lastCurrentPage; i++) {
                items.push(i)
            }
            return items;
        },
        lastItemsForCurrentPagesArr() {
            let app = this;
            let lastPage = app.items.meta.current_page;
            let firstLastPage = lastPage - 4;
            let items = [];
            for (let i = firstLastPage; i <= lastPage; i++) {
                items.push(i)
            }
            return items;
        },
        filterQuery() {
            let app = this;
            return Helper.objToUrlParams(app.filterParams);
        },
    },
    created() {
        let app = this;
        app.items = app.rows;
        app.filterParams = app.filters;
    },
    watch: {
        rows(value) {
            let app = this;
            app.items = value;
        },
        filters(value) {
            let app = this;
            app.filterParams = value
        }
    },
}
</script>