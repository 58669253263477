<template>
   <section class="page-wrap py-5">
        <div class="container">
            <div class="row">
                <div class="col-xl-3">
                    <div class="display-card sticky-top">
                    <div class="userCard">
                        <div class="user-edit-wrapper">
                            <a v-if="editProfile" class="user-edit-btn" href="javascript:void(0);" @click="editProfileAction(false)">
                                <div class="icon-close"></div>
                            </a>
                            <a v-else class="user-edit-btn" href="javascript:void(0);" @click="editProfileAction(true)">
                                <div class="edit-btn"></div>
                            </a>
                        </div>
                        <div class="user-image mb-3">
                            <img :src="user.avatar_path" >
                        </div>
                        <div class="user-details-wrapper">
                            <h2>{{ user.name }}</h2>
                            <ul>
                                <li>
                                {{user.email}}
                                </li>
                                <li>
                                    {{ user.mobile_number }}
                                </li>
                                <li>
                                    {{ user.address }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="user-actions mt-4">
                        <div class="mob-edit-profile">
                            <a v-if="editProfile" class="edit-profile-mob" href="javascript:void(0);" @click="editProfileAction(false)">
                               Edit Profile
                            </a>
                            <a v-else class="edit-profile-mob" href="javascript:void(0);" @click="editProfileAction(true)">
                                Edit Profile
                            </a>
                        </div>
                        <div class="display-line"></div>
                        <div class="mob-logout-action">
                            <a class="logout-profile" @click="logoutAction">Logout</a>
                        </div>
                    </div>
                </div>
                </div>
                <div class="col-xl-9">
                    <div class="profile-Edit-wrapper mt-3" v-if="editProfile">
                        <div class="page-title mb-3">
                            <h2>Profile Edit</h2>
                        </div>
                        <div class="profileForm-Edit display-card">
                            <div class="profileForm">
                                <div class="form-group mb-3">
                                    <label>Full Name <span class="required-field">*</span></label>
                                    <input type="text" v-model="userProfile.name"  class="univ-input">
                                    <span class="error-field" v-if="updateErrors.name"><span class="icon-warning"></span> {{ updateErrors.name }}</span>
                                </div>
                                <div class="form-group mb-3">
                                    <label>Contact No. <span class="required-field">*</span></label>
                                    <input type="number" v-model="userProfile.mobile_number" class="univ-input">
                                    <span class="error-field" v-if="updateErrors.mobile_number"><span class="icon-warning"></span> {{ updateErrors.mobile_number }}</span>
                                </div>
                                <div class="form-group mb-3">
                                    <label>Address <span class="required-field">*</span></label>
                                    <input type="text" v-model="userProfile.address" class="univ-input">
                                    <span class="error-field" v-if="updateErrors.address"><span class="icon-warning"></span> {{ updateErrors.address }}</span>
                                </div>
                                <div class="form-group d-flex align-items-center mb-3">
                                    <div class="image-section" style=" text-align: center; " v-if="image">
                                        <img class="preview-img upprofile-img" :src="generateURL(image)" />
                                    </div>
                                    <div class="image-section" style=" text-align: center; " v-else>
                                        <img class="preview-img upprofile-img" :src="user.avatar_path" />
                                    </div>
                                    <div class="file-input upload-btn">
                                        <input type="file" name="file-input" id="file-input" class="file-input__input" @change="onImageChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />
                                        <label class="file-input__label" for="file-input">Upload Image</label>
                                    </div>
                                </div>
                                <div class="form-group d-flex align-items-center mb-3">
                                    <label class="switch mb-0 me-2">
                                        <input type="checkbox" v-model="change_pw">
                                        <span class="slider round"></span>
                                    </label>
                                    <p class="mb-0">Password change</p>
                                </div>
                                <div class="form-group row" v-if="change_pw">
                                    <div class="col-xl-6 mb-3">
                                        <label>Old Password <span class="required-field">*</span></label>
                                        <input type="text" v-model="userProfile.old_password" class="univ-input">
                                        <span class="error-field" ></span>
                                    </div>
                                    <div class="col-xl-6 mb-3" >
                                        <label>New Password <span class="required-field">*</span></label>
                                        <input type="text" v-model="userProfile.new_password" class="univ-input">
                                        <span class="error-field" ></span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <span class="error-message" v-if="errorMessage && change_pw"><span class="icon-warning"></span> {{ errorMessage }}</span>
                                    <button class="univ-btn w-100" @click="formSubmit" ><b-spinner v-if="loginLoader" small></b-spinner> Update</button>
                                </div>
                            </div> 
                        </div>  
                    </div>
                    <div class="userProperty" v-else>
                        <div class="page-title mt-3">
                            <h2>Your Property List</h2>
                        </div>
                        <template  v-if="dataStatus">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-6 mt-4 item-relative" v-for="(item,i) in properties.data" :key="i">
                                <ItemList :item="item" />
                                <b-dropdown class="ellipse-edit" right variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template #button-content>
                                        <span class="icon-ellipse"></span>
                                    </template>
                                    <b-dropdown-item href="" @click="editProperty(item.property.slug)" >Edit Item</b-dropdown-item>
                                    <b-dropdown-item href="" @click="removedProperty(item.id)">Remove Item</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center mt-4">
                                <Pagination :rows.sync="properties" :filters="filterData" @meta-action="handeleMetaEvent($event)"/>
                            </div>
                        </div>
                        </template>
                        <template v-else> <NotFound /> </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"
import store from "@/store"
import ItemList from '@/components/ItemList'
import NotFound from '@/components/NotFound'
import Pagination from '@/components/Pagination'
export default {
    components: { 
        Pagination, 
        ItemList,
        NotFound,
    },
    data() {
        return {
            user:{
                name: store.getters.user.name,
                email: store.getters.user.email,
                mobile_number: store.getters.user.mobile_number,
                address: store.getters.user.address,
                avatar_path: store.getters.user.avatar_path
            },
            properties: { 
                data: [], 
                links: {}, 
                meta: {}
            },
            filterData:{
                purpose:this.$route.query.purpose,
                keywords:this.$route.query.keywords,
                category:this.$route.query.category,
                location:this.$route.query.location,
                type:this.$route.query.type,
                sort:this.$route.query.sort?this.$route.query.sort:'latest',
            },
            dataStatus: true,
            userId:store.getters.user.id,
            userProfile:{
                id: store.getters.user.id,
                name: store.getters.user.name,
                mobile_number: store.getters.user.mobile_number,
                address: store.getters.user.address,
                avatar: store.getters.user.avatar,
                avatar_path: store.getters.user.avatar_path,
                new_avatar:"",
                old_password:"",
                new_password:"",
                pw_option:'disabled',
            },
            change_pw:false,
            image:"",
            loginLoader: false,
            errorMessage:"",
            updateErrors: {
                name:"",
                mobile_number:"",
                address: "",
            },
            editProfile:false,
        };
    },
    mounted() {
        let app = this;
        app.$root.$on("profile-update", ($event) => {
            app.user.name = $event.name;
            app.user.email = $event.email;
            app.user.mobile_number = $event.mobile_number;
            app.user.address = $event.address;
            app.user.avatar_path = $event.avatar_path;
        });
        app.$root.$on("property-updated", ($event) => {
            if($event){
                app.renderProperty(app.filterData);
            }
        });
        app.renderProperty(app.filterData);
    },
    methods:{
        async renderProperty(filters =[],page=""){
            Helper.showSpinner();
            let app = this;
            let url;
            if (filters) {
                let filterUrl = Helper.objToUrlParams(filters);
                url = `/user/`+app.userId+`/properties?${filterUrl}${page}`;
            } else {
                url = `/user/`+app.userId+`/properties`;
            }
            await axios.get(api.getUrl(url)).then(function (response) {
                let responseData        = response.data.data;
                if(response.data.success){
                    app.dataStatus = true;
                    app.properties.data     = responseData.data;
                    app.properties.meta     = responseData.meta;
                    app.properties.links    = responseData.links;
                    
                    app.from    =   responseData.meta.from;
                    app.to      =   responseData.meta.to;
                    app.total   =   responseData.meta.total;
                    app.$emit('totla-properties' , responseData.meta.total);
                }else{
                    app.from    =   0;
                    app.to      =   0;
                    app.total   =   0;
                    app.dataStatus = false;
                    app.properties.data     = [];
                    app.$emit('totla-properties' , 0);
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
        closeModal() {
            let app = this;
            app.$bvModal.hide('profileModal');
        },
        onImageChange(e){
            let app = this;
            app.image = e.target.files[0];
        },
        formSubmit(e) {
            e.preventDefault();
            let app = this;
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            app.loginLoader = true;
            app.userProfile.new_avatar = app.image;
            if(app.change_pw){
                app.userProfile.pw_option = "enabled";
            }else{
                app.userProfile.pw_option = "disabled";
            }
            axios.post(api.getUrl('/user/update'), app.userProfile, config).then(function (response) {
                if(response.data.success == true){
                    app.loginLoader = false;
                    store.commit("setUserData",response.data.data);
                    app.$root.$emit('profile-update',response.data.data);
                    app.$toast.success(response.data.message,{position: "top-center",timeout: 1500});
                }else{
                    app.loginLoader = false;
                    if(response.data.message){
                        app.loginLoader = false;
                        app.errorMessage = response.data.message;
                        app.$toast.error(response.data.message,{position: "top-center",timeout: 1500});
                    }else if(response.data.errors.length > 0){
                        app.loginLoader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "name"){
                                app.updateErrors.name = item.message;
                            }else if(item.key == "mobile_number"){
                                app.updateErrors.mobile_number = item.message;
                            } else if(item.key == "address"){
                                app.updateErrors.address = item.message;
                            }
                        }); 
                    }
                }
            });
        },
        generateURL(file) {
            let fileSrc = URL.createObjectURL(file);
            setTimeout(() => {
                URL.revokeObjectURL(fileSrc);
            }, 1000);
            return fileSrc;
        },
        editProfileAction($val){
            let app = this;
            app.editProfile = $val;
        },
        logoutAction() {
            let app = this;
            Helper.logout();
            app.isAuthenticated = false;
            app.$router.push({ path: '/' });
        },
        editProperty(slug){
            let app = this;
            app.$root.$emit("property-slug", slug);
            setTimeout(() => {
                app.$bvModal.show('addPropertyModal');
            }, 1500);
        },
        removedProperty(property_id){
            if(confirm("Do you really want to delete?")){
                let app = this;
                Helper.showSpinner();
                axios.post(api.getUrl('/user/property/delete'), {id:property_id}).then(function (response) {
                    if(response.data.success == true){
                        app.$toast.success(response.data.message,{position: "top-center",timeout: 2000});
                    }else{
                        app.$toast.error(response.data.message,{position: "top-center",timeout: 1500});
                    }
                    app.renderProperty(app.filterData);
                }).finally(() => {
                    Helper.hideSpinner();
                });
            }
        }
    },
    watch: {
        $route() {
            if (store.getters.authenticated) {
                this.isAuthenticated = true;
                this.user = store.getters.user;
                this.avatar_path = store.getters.user.avatar_path;
            } else {
                this.isAuthenticated = false;
            }
        }
    }
}
</script>